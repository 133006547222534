.MuiPaper-root.MuiCard-root.team-info-card {
    box-shadow: var(--shadow3);
    border-radius: 10px;
}

.team-info-card .avatar {
    height: 72px;
    width: 72px;
    border-radius: 20px;
}

.team-info-card .isAdmin{
    min-height: 22px;
    background: var(--bg9);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    border-radius: 7px;
    color: var(--bg6);
}


.team-info-card .text-container {
    background: var(--bg1);
    min-height: 22px;
    border-radius: 2px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
}

.team-info-card .vertical-line {
    min-height: 22px;
    width: 1px;
    background: #000
}