.slider-img {
  border-radius: 10px;
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

.rec.rec-dot.rec-dot_active {
  background-color: var(--bg6);
  box-shadow: 0 0 1px 3px var(--bg6);
}

.text-header {
  margin: 10px !important;
  font-size: 20px;
  font-family: "DM Sans", sans-serif;
  color: "var(--primary-color-700)";
}

.upcomming {
  background-color: #EFF8FF;
  /* height: 70%; */
  min-height: 50vh;
  padding-left: 15px;
  padding-right: 15px;
}

.upcomming>.text-header {
  padding-top: 10px;
}

.no-matches {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  background-color: #2549720D;
}

.no-matches div {
  padding-right: 15px;
}

.no-matches p {
  width: 65%;
  text-align: center;
  padding: 0px !important;
  margin: 0px !important;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid black;
}