.TeamInfoBigCard {
    background: var(--bg8);
    overflow-x: hidden;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.TeamInfoBigCard .teamLogo {
    width: 100%;
    height: 133px;
    object-fit: cover;
    object-position: center;
}

.TeamInfoBigCard .teamInfoContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}