/* Login Screen */
.password-error {
  color: var(--error-color);
}

.forgot-password {
  color: var(--info-color);
}

.card-actions {
  padding: 0 !important;
  margin-top: 5%;

  .div {
    height: 15vh;
  }
}

.other-login {
  display: flex;
  margin-top: 12%;
  width: 100%;
  height: 40px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.other-login p {
  font-size: medium;
  color: var(--primary-color-700);
}

.other-login .line {
  height: 1px;
  background-color: var(--primary-color-700);
  width: 25%;
}

h4 {
  text-align: center;
  color: var(--info-color);
  cursor: pointer;
}


/* Registrer */
.form-container {
  position: relative;
  display: flex;
  width: 490px;
  padding-top: 5%;
}

/*TODO: This naming doesn't make sense*/
.card {
  width: 100%;
}

.logo {
  width: 50%;
}

@media (max-width: 768px) {
  .form-container {
    width: 92%;
  }
}

.error-message {
  color: red;
  margin-top: 5px;
  font-size: 14px;
}

/* Otp screen */
.otp-container {
  width: 100% !important;
}

.otp-form-container {
  padding-top: 5% !important;
}

@media (max-width: 768px) {
  .otp-container {
    height: 100vh !important;
  }
}

.flex-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.flex-item {
  flex: 1;
}