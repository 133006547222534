.intro-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro-modal-content {
    position: relative;
    width: 90%;
    height: 90%;
    /* max-width: 600px; */
    background-color: white;
    padding: 3%;
    border-radius: 10px;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.skip {
    /* position: absolute;
    top: 20px;
    right: 20px; */
    /* cursor: pointer;
    color: #6f6c6f;
    font-family: DM Sans;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: end;
    padding: 5px; */
    display: flex;
    justify-content: end;
}

.slide {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
}

.slide-img {
    margin-left: 10%;
    width: 350px;
    /* max-width: 200px; */
    height: 350px;
}

.intro-card {
    margin-top: 20px;
    width: 100%;
    background: linear-gradient(90deg, var(--netural-color-900), #8bb0da);
    color: #fff;
    border-radius: 10px !important;
    padding: 20px !important;
}

.title {
    font-family: Poppins !important;
    font-size: 22px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    text-align: left;
    margin: 5px 0 !important;
    color: #fff !important;
}

.description {
    font-family: Poppins !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    text-align: center;
    margin: 15px 0 !important;
    color: #fff !important;
}

.next-button {
    display: flex !important;
    align-self: center;
    margin: 30px auto !important;
    width: 100%;
}

.slick-next,
.slick-prev {
    display: none !important;
}