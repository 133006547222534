@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap);
:root {
  --primary-color: #254972;
  --neutral-white: #F7F7F7;
  --status-info: #0094F6;
  --text-secondary: #6F6C6F;

  --primary-color-white: #ffffff;
  --primary-color-200: #f8f8f8;
  --primary-color-300: #f2f2f2;
  --primary-color-400: #e4e4e4;
  --primary-color-500: #c4c4c4;
  --primary-color-600: #888888;
  --primary-color-700: #333333;

  --secondary-color-050: #e3ffec;
  --secondary-color-100: #baffcf;
  --secondary-color-200: #84ffae;
  --secondary-color-300: #00ff88;
  --secondary-color-400: #00fb66;
  --secondary-color-500: #00f44e;
  --secondary-color-600: #00e242;
  --secondary-color-700: #00ce33;
  --secondary-color-800: #00bb26;
  --secondary-color-900: #009909;

  --netural-color-050: #e5f1f6;
  --netural-color-100: #bfdceb;
  --netural-color-200: #9ac7df;
  --netural-color-300: #79b1d1;
  --netural-color-400: #63a2c8;
  --netural-color-500: #5093c1;
  --netural-color-600: #4686b5;
  --netural-color-700: #3b75a4;
  --netural-color-800: #326592;
  --netural-color-900: #254972;

  --fill-blue-color: #224a78e5;
  --fill-green-color: #00ff88;
  --fill-red-color: #f95e50;
  --fill-black-color: #1e1e1e;
  --fill-lightyellow-color: #FDFFB6;


  /* Toast colors */
  --success-color: #00c814;
  --warning-color: #ffc700;
  --error-color: #f30000;
  --info-color: #0091ff;

  /* extra customize color */
  --color-forgot-password: #0094F6; /*check the status info colour*/
  --color-orange: #FF8050;
  --card-1-bg: #EFF8FF;
  --card-2-bg: #EEF0FF;
  --placeholder-bg: #F0EDF1;
  --delete-btn-bg: #FFADAD;
  --bg1: #EDE8EC;
  --bg2: #84FFAE;
  --bg3: #CAFFBF59;
  --bg4: #9AC7DF40;
  --bg5: #D9D9D9;
  --bg6: #194055;
  --bg7: #194055ca;
  --bg8: #79B1D1;
  --bg9: #E8EEF8;
  --bg10: #CAFFBF;

  --color1: #89898D;
  --color2: #181518;
  --color3: #6F6C6F;

  --shadow1: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  --shadow2: 0px 2px 6px 0px #00000040;
  --shadow3: 0px 1px 10px 0px #19405540;
}
body {
  color: #181518;
  background-color: #fff !important;
  margin: 0;
  font-family: "DM Sans", sans-serif, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-secondary {
  color: var(--text-secondary) !important;
}

.text-info {
  color: var(--status-info) !important
}

.text-red {
  color: var(--error-color);
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-blue-bg {
  background: var(--primary-color);
  color: white;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-weight: 500 !important;
  border-radius: 5px;
  padding: 1.5%;
  font-size: 16px;
  box-shadow: 0 0 16px 0 rgb(199 209 212 / 86%);
}

.text-white-bg {
  background: white;
  color: var(--primary-color-600);
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-weight: 500 !important;
  border-radius: 5px;
  padding: 5px 5px;
  box-shadow: 0 0 8px 0 rgba(119, 168, 184, 0.86);
}

.text-chip {
  background: var(--secondary-color-900);
  color: var(--primary-color-600);
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-weight: 500 !important;
  border-radius: 5px;
  padding: 5px 15px 5px 15px;
}

.chip-small {
  font-size: 12px;
  padding: 2px 7px 2px 7px;
  border-radius: 5px;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.me-15 {
  margin-right: 15px !important;
}

.ms-15 {
  margin-left: 15px !important;
}

.mx-15 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pe-15 {
  padding-right: 15px !important;
}

.ps-15 {
  padding-left: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.g-05 {
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
}

.d-flex {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-vertical {
  display: flex;
  flex-direction: column !important;
  align-items: left;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
}

.page-wrapper {
  margin: 15px;
}

.search-players {
  margin-top: 15px;
  margin-bottom: 15px;
  overflow-y: auto;
  height: 30vh;
  text-align: center;
  font-family: "DM Sans", sans-serif;
}

.card {
  margin-bottom: 15px;
  padding: 10px;
  /* box-shadow: var(--main-box-shadow) !important; */
  font-family: "DM Sans", sans-serif;
  width: 100% !important;
}

.card.shadow {
  box-shadow: 0 0 8px 0 rgba(119, 168, 184, 0.86) !important;
}

.scoresheetcard {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: var(--main-box-shadow) !important;
  color: #992c2c;
  width: 355px;
}

.card .card-image {
  width: 40px;
  height: 40px;
  border-radius: 4%;
}

.card .card-image-lg {
  width: 60px;
  height: 60px;
  border-radius: 25%;
}

.bottom-center {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.blur-bg {
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  background-color: rgb(255, 255, 255, 0.4);
}

.fixed {
  position: fixed;
}

:root {
  --main-box-shadow: 0 4px 7px rgb(30, 30, 30, 0.15);
  --main-border: 2px solid;
  --primary-hover-color: #505050;
  --card-margin-S: 10px;
  --card-margin-M: 20px;
  --card-padding: 16px;
  --card-padding-tiny: 10px;
  --card-width: 480px;
  font-family: "DM Sans", sans-serif !important;
}

* {
  box-sizing: border-box;
}

.hide {
  display: none;
}

.app-container {
  /* height: 93%; */
  display: flex;
  background-color: var(--primary-color-white);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}

.score-ball {
  height: 25px;
  width: 25px;
  background-color: #00a0f0;
  border-radius: 50%;
  font-family: "DM Sans", sans-serif;
}

.series-card {
  background-image: unset !important;
  background-color: #fafafa !important;
}

.label {
  background: linear-gradient(45deg, white 30%, white 90%);
  position: relative;
  border-radius: 3px;
  border: 0;
  color: var(--bg6);
  text-align: center;
  border-radius: 12px;
  width: auto;
  height: 48px;
  padding: 0 0px;
  font-size: 21px;
  box-shadow: 0 0 16px 0 rgb(199 209 212 / 86%);
  font-family: "DM Sans", sans-serif;
  text-transform: capitalize;
}

@media (max-width: 770px) {
  .app-container {
    margin: 0 0;
    align-items: center;
    justify-content: center;
  }

  .image {
    width: 100%;
  }

  .MuiTableCell-head:nth-child(1)>span:nth-child(1) {
    padding-right: 0px !important;
  }

  br.break {
    display: block !important;
  }

  .flex-col {
    flex-direction: column;
  }

  .balls {
    width: 25px !important;
    height: 25px !important;
  }

  .team-wrapper {
    min-width: 5ch !important;
    max-width: 10ch !important;
    padding: 0 4px;
  }

  /* MUIDataTable */
  th {
    font-size: 16px !important;
    text-align: center;
  }

  td {
    font-size: 14px !important;
    text-align: center;
  }

  td.MuiTableCell-body:nth-child(1),
  td.MuiTableCell-head:nth-child(1) {
    max-width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiTableCell-head:nth-child(1)>span:nth-child(1) {
    padding-right: 2px !important;
  }

  .-p-tb {
    padding-top: 60px !important;
    padding-bottom: 30px !important;
  }

  .-p-rl {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* Scoresheet */
  div.card-outlined {
    border-color: transparent !important;
    border-radius: 10%;
  }

  div.form-margin {
    margin-bottom: var(--card-margin-S);
  }

  div.card-margin {
    margin-bottom: var(--card-margin-M);
  }
}

@media print {
  body {
    font-size: 10pt;
  }
}

@media screen {
  body {
    font-size: 13px;
  }
}

@media screen,
print {
  body {
    line-height: 1.2;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) and (resolution: 150dpi) {
  body {
    line-height: 1.4;
  }
}

@media only screen and (max-width: 480px) {
  /* div.button-group-balls { */
  /* width: 100%; */
  /* display: flex; */
  /* margin-bottom: 2px; */
  /* border: 0px; */
  /* } */

  /* div.button-group-balls>button { */
  /* width: 100%; */
  /* margin-left: 10px; */
  /* margin-right: 10px; */
  /* border-radius: 10px !important; */
  /* border-right: 2px solid var(--primary-color-600) !important; */
  /* border-left: 2px solid var(--primary-color-600); */
  /* border-bottom: 2px solid var(--primary-color-600); */
  /* border-top: 2px solid var(--primary-color-600); */
  /* color: var(--primary-color-600); */
  /* font-size: 14px !important; */
  /* text-transform: capitalize; */
  /* } */

  /* div.button-group-ball-type-runs>button { */
  /* font-size: 22px !important; */
  /* font-weight: bold; */
  /* } */

  br.break {
    display: block !important;
  }

  .flex-col {
    flex-direction: column;
  }

  .balls {
    width: 25px !important;
    height: 25px !important;
  }

  .team-wrapper {
    min-width: 5ch !important;
    max-width: 10ch !important;
    padding: 0 4px;
  }

  /* MUIDataTable */
  th {
    font-size: 16px !important;
  }

  td {
    font-size: 14px !important;
  }

  td.MuiTableCell-body:nth-child(1),
  td.MuiTableCell-head:nth-child(1) {
    max-width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiTableCell-head:nth-child(1)>span:nth-child(1) {
    padding-right: 2px !important;
  }

  .-p-tb {
    padding-top: 60px !important;
    padding-bottom: 30px !important;
  }

  .-p-rl {
    padding-left: 1px;
    padding-right: 1px;
  }

  div.card-outlined {
    border-color: transparent !important;
    color: var(--bg6);
  }

  div.form-margin {
    margin-bottom: var(--card-margin-S);
  }

  div.card-margin {
    margin-bottom: var(--card-margin-M);
  }
}

.-p-tb {
  padding-top: 80px;
  padding-bottom: 50px;
}

.score-sum {
  text-align: center;
  padding: 10px;
  border: var(--main-border);
  border-radius: 10px;
  color: var(--primary-color-600);
  width: 100%;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.balls-container {
  padding: 10px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: var(--main-border);
  border-radius: 10px;
  font-size: 14px;
  color: var(--primary-color-600);
}

.balls {
  width: 41px;
  height: 41px;
  border-radius: 100%;
  border: var(--main-border);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  font-weight: 300;
}

.balls-with-runs {
  background: var(--primary-color-600);
  font-family: "DM Sans", sans-serif;
}

.balls-with-runs>span {
  color: #fff;
}

div.card-header {
  border-color: var(--primary-color-600);
  color: var(--primary-color-600);
  border-radius: 20px;
}

.team-wrapper {
  margin-left: 4px;
  margin-right: 4px;
  width: 50%;
  min-width: 16ch;
  max-width: 45ch;
  display: inline-block;
  border: var(--main-border);
  /* text-align: center; */
  border-radius: 20px;
}

/* MUIDataTable */

td.MuiTableCell-body:nth-child(1),
td.MuiTableCell-head:nth-child(1) {
  text-align: left;
}

.MuiTableCell-head:nth-child(1)>span:nth-child(1) {
  padding-right: 75px;
  justify-content: start;
}

div.card-match,
div.card-series {
  box-shadow: var(--main-box-shadow);
  margin-bottom: var(--card-margin-M);
  border-radius: 30px;
}

div.card-match:last-child,
div.card-series:last-child {
  margin-bottom: 0;
}

div.card-match>div.MuiCardContent-root {
  padding: 0px;
}

a.check-score,
a.check-series {
  background: linear-gradient(45deg, white 30%, white 90%);
  position: relative;
  border-radius: 3px;
  border: 0;
  color: var(--primary-color-600);
  text-align: center;
  border-radius: 12px;
  /* width: auto; */
  /* height: 48px; */
  /* padding: 0 0px; */
  font-size: 20px;
  padding: 0 0;
  box-shadow: 0 0 16px 0 rgb(199 209 212 / 86%);
  text-transform: capitalize;
}

/* Scoresheet */

div.card-expand {
  transform-origin: top;
  animation: expand 0.6s 1 ease;
}

div.card-shrink {
  transform-origin: top;
  animation: shrink 0.3s 1 forwards ease;
}

div.card-outlined {
  border-color: var(--primary-color-600);
  margin-bottom: var(--card-margin-M);
}

div.button-group-balls {
  width: 100%;
  display: flex;
  margin-bottom: 2px;
  border: 0px;
}

div.button-group-balls>button {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px !important;
  border: 2px solid var(--primary-color-600) !important;
  color: var(--primary-color-600);
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
}

div.button-group-balls-runout>button {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px !important;
  border: 2px solid var(--primary-color-600) !important;
  color: var(--primary-color-600);
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
}

div.button-group-balls-runout {
  width: 100%;
  display: flex;
  margin-bottom: 2px;
  border: 0px;
}

div.button-group-ball-type-runs>button {
  font-size: 64px;
  font-weight: bold;
}

button.button-end-ball {
  background: linear-gradient(45deg,
      var(--primary-color-600) 30%,
      var(--primary-color-600) 90%);
  color: #fff !important;
}

.end-game {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.end-game>a {
  background: linear-gradient(45deg,
      var(--primary-color-600) 30%,
      var(--primary-color-600) 90%);
  width: 100%;
  margin-top: var(--card-margin-S);
  border-radius: 4px !important;
  color: #fff;
  font-size: 18px;
  font-family: "DM Sans", sans-serif;
}

button.button-end-match {
  background: linear-gradient(45deg,
      var(--primary-color-600) 30%,
      var(--primary-color-600) 90%);
  width: 100%;
  margin-top: var(--card-margin-S);
  border-radius: 4px !important;
  color: #fff;
  font-size: 18px;
  font-family: "DM Sans", sans-serif;
}

button.button-end-match-disabled {
  /* background: #bbb; */
  border-color: transparent;
  font-family: "DM Sans", sans-serif;
}

button.button-scorescheme {
  box-shadow: 0 0 0 rgb(0, 0, 0, 0);
}

button.active-disabled.Mui-disabled {
  background: linear-gradient(45deg,
      var(--primary-color-600) 30%,
      var(--primary-color-600) 90%);
  /* border: 2px solid var(--primary-color-600) !important; */
}

button.active-disabled>span {
  color: #329fc3 !important;
  color: #1a91c7 !important;
}

button.no-ball-active.Mui-disabled {
  background: #7c1f1f;
  border-color: #7c1f1f !important;
  font-weight: 700;
}

button.no-ball-active>span {
  color: #7c1f1f !important;
  font-weight: 700;
}

button.wide-ball-active.Mui-disabled {
  background: #7c1f1f;
  border-color: #7c1f1f !important;
  font-weight: 700;
}

button.wide-ball-active>span {
  color: #7c1f1f !important;
  font-weight: 700;
}

button.bye-active-disabled>span {
  color: #7c1f1f !important;
  font-weight: 700;
}

button.bye-active-disabled.Mui-disabled {
  background: #7c1f1f;
  border-color: #7c1f1f !important;
  font-weight: 700;
}

button.runout-active-disabled>span {
  color: #7c1f1f !important;
  font-weight: 700;
}

button.runout-active-disabled.Mui-disabled {
  background: #7c1f1f;
  border-color: #7c1f1f !important;
  font-weight: 700;
}

button.label-disabled.Mui-disabled {
  color: #538292e0 !important;
  color: var(--primary-color-600);
  background: var(--secondary-color-900);
  border: 2px solid rgb(0, 0, 0, 0) !important;
  font-family: "DM Sans", sans-serif;
}

div.scorescheme {
  border-radius: 15px;
  border: 2px solid rgb(0, 0, 0, 0) !important;
  /* width: 100%; */
}

/* on error */
div.MuiOutlinedInput-root.Mui-error:hover fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
}

/* svg arrow on error */
div.MuiOutlinedInput-root.Mui-error>svg.MuiSelect-icon {
  color: #f44336 !important;
}

div.MuiOutlinedInput-root.Mui-error.Mui-focused>svg.MuiSelect-icon {
  color: var(--primary-color-600) !important;
}

div.MuiOutlinedInput-root.Mui-error.Mui-focused:hover fieldset.MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-color-600) !important;
}

div.MuiAlert-root {
  border-radius: 999px;
}

/* Shake on error */

.shake {
  animation: kf_shake 0.4s 1 linear;
  -webkit-animation: kf_shake 0.4s 1 linear;
  -moz-animation: kf_shake 0.4s 1 linear;
  -o-animation: kf_shake 0.4s 1 linear;
}

.reveal {
  animation: reveal 0.2s 1 ease-in;
}

@keyframes expand {
  0% {
    max-height: 0px;
  }

  50% {
    max-height: 290px;
  }

  100% {
    max-height: 550px;
  }
}

@keyframes shrink {
  0% {
    /* margin-bottom: 10px; */
    max-height: 550px;
  }

  100% {
    visibility: hidden;
    margin-bottom: -10px;
    max-height: 0px;
  }
}

@keyframes reveal {
  0% {
    height: 0px;
  }

  30% {
    height: 20px;
  }

  60% {
    height: 60px;
  }

  100% {
    height: auto;
  }
}

@keyframes kf_shake {
  0% {
    transform: translate(30px);
  }

  20% {
    transform: translate(-30px);
  }

  40% {
    transform: translate(15px);
  }

  60% {
    transform: translate(-15px);
  }

  80% {
    transform: translate(8px);
  }

  100% {
    origin-transform: translate(0px);
  }
}

a {
  text-decoration: none;
}

a>h6:hover {
  color: var(--primary-hover-color) !important;
}

/* conflict resolve incoming change */
div.MuiCardActions-root {
  justify-content: center;
}

br.break {
  display: none;
}
/* Login Screen */
.password-error {
  color: var(--error-color);
}

.forgot-password {
  color: var(--info-color);
}

.card-actions {
  padding: 0 !important;
  margin-top: 5%;

  .div {
    height: 15vh;
  }
}

.other-login {
  display: flex;
  margin-top: 12%;
  width: 100%;
  height: 40px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.other-login p {
  font-size: medium;
  color: var(--primary-color-700);
}

.other-login .line {
  height: 1px;
  background-color: var(--primary-color-700);
  width: 25%;
}

h4 {
  text-align: center;
  color: var(--info-color);
  cursor: pointer;
}


/* Registrer */
.form-container {
  position: relative;
  display: flex;
  width: 490px;
  padding-top: 5%;
}

/*TODO: This naming doesn't make sense*/
.card {
  width: 100%;
}

.logo {
  width: 50%;
}

@media (max-width: 768px) {
  .form-container {
    width: 92%;
  }
}

.error-message {
  color: red;
  margin-top: 5px;
  font-size: 14px;
}

/* Otp screen */
.otp-container {
  width: 100% !important;
}

.otp-form-container {
  padding-top: 5% !important;
}

@media (max-width: 768px) {
  .otp-container {
    height: 100vh !important;
  }
}

.flex-container {
  display: flex;
  justify-content: space-between;
  grid-gap: 15px;
  gap: 15px;
}

.flex-item {
  flex: 1 1;
}
.primary-btn {
  background-color: var(--netural-color-900) !important;
  color: white !important;
  font-family: "DM Sans", sans-serif !important;
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
}

.primary-btn:disabled {
  background-color: #f0edf1 !important;
  color: #bdbabd !important;
}

.light-btn {
  background-color: var(--primary-color-400) !important;
  color: var(--fill-black-color) !important;
}

.primary-hollow-btn {
  color: var(--primary-color-600) !important;
  border-color: var(--primary-color-600) !important;
  font-family: "DM Sans", sans-serif !important;
  background: white;
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
}

.delete-btn {
  background-color: var(--error-color) !important;
  color: var(--primary-color-white) !important;
  border-color: var(--error-color) !important;
}

.cancel-btn {
  color: var(--error-color) !important;
  border-color: var(--error-color) !important;
  font-size: 14px !important;
}

.MuiTextField-root {
  /* box-shadow: var(--main-box-shadow); */
  border-radius: 5px !important;
  font-family: "DM Sans", sans-serif !important;
}

.MuiOutlinedInput-input {
  font-family: "DM Sans", sans-serif !important;
}

.MuiTypography-body1 {
  font-family: "DM Sans", sans-serif !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-color-500) !important;
  border-radius: 5px !important;
}

.MuiInputBase-input {
  font-family: "DM Sans", sans-serif !important;
  font-size: 16px !important;
}

.MuiFormLabel-root {
  color: var(--primary-color-700) !important;
  font-family: "DM Sans", sans-serif !important;
}

.MuiSelect-icon {
  color: var(--primary-color-600) !important;
  font-family: "DM Sans", sans-serif !important;
}

.MuiSelect-select {
  font-family: "DM Sans", sans-serif !important;
}

button.tab {
  background: white !important;
  color: var(--netural-color-900);
  border: 1px solid;
  font-family: "DM Sans", sans-serif;
  text-transform: capitalize;
}

button.active-tab {
  background: var(--netural-color-900) !important;
  color: white;
  font-family: "DM Sans", sans-serif !important;
  text-transform: capitalize;
}

.MuiRadio-root {
  color: var(--primary-color-600) !important;
  font-family: "DM Sans", sans-serif !important;
}

.MUIDataTableBodyCell-root-781 {
  text-align: "center";
}

.customSmallButton {
  border: none !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  font-family: "DM Sans", "sans-serif" !important;
  padding: 4px 15px !important;
  cursor: pointer;
}

/* CustomOtpInput.css */
.otp-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.otp-input {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  font-size: 18px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

/* CustomMobileInput.css */
.custom-mobile-input-container {
  display: flex;
  background-color: var(--placeholder-bg);
  flex-direction: column;
  /* margin-bottom: 1rem; */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-top: 16px;
  margin-bottom: 8px;
}

.custom-mobile-input-label {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
}

.custom-phone-input .form-control {
  height: auto !important;
  padding: 0 10px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  align-items: center;
  width: 100% !important;
  padding-left: 55px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  font-size: 16px;
  background-color: var(--placeholder-bg) !important;
}

.custom-phone-input .form-control:focus {
  border-color: #e4e4e4;
  background-color: var(--placeholder-bg);
  box-shadow: 0 0 0 2px rgba(127, 127, 128, 0.2);
}

.custom-phone-input .flag-dropdown {
  /* height: 40px; */
  border: none;
  background: none;
  padding: 5px !important;
}

.custom-phone-input .flag-dropdown .selected-flag {
  display: flex;
  align-items: center;
}

.custom-phone-input .flag-dropdown .selected-flag .flag {
  margin-right: 8px;
}

/* CustomMUI.css */

.container {
  padding: 16px;
  position: relative;
}

/* TODO: Remove this check flex-between class */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* TODO: Remove this check the new reusable bottom sheet  */
.close-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

/* TODO: Remove this check mt-15 & text-center classes */
.content {
  text-align: center;
  margin-top: 16px;
}

/* TODO: Remove this check the new reusable bottom drawer component  */
.sheet-header-text {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--primary-color-700);
}

/* TODO: Remove this check the new reusable bottom drawer component  */
.sheet-description-text {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--primary-color-700);
}

.button-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.card-details {
  width: 65%;
  border-bottom: 1px solid black;
}

/* MatchCard Compoent CSS Start */

.matches-card {
  padding: 10px;
  border-radius: 10px;
  background: #2549720D;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 10px 0px #25497240;
}

.dateTeamStatusContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
}

.dateTeamStatusContainer> :nth-child(1) {
  text-align: start;
  width: 25%;
}

.dateTeamStatusContainer> :nth-child(1)>button {
  text-wrap: nowrap;
}

.dateTeamStatusContainer> :nth-child(2) {
  width: 50%;
}

.dateTeamStatusContainer> :nth-child(3) {
  text-align: end;
  width: 25%;
}

.noOfmatchGroundTournamentNameConatiner {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  grid-column-gap: 6px;
  column-gap: 6px;
  grid-row-gap: 10px;
  row-gap: 10px;
  margin: 10px 0;
}

.noOfmatchGroundTournamentNameConatiner>p,
.teamsNameScoreContainer>div>p:nth-child(1) {
  margin: 0;
  background: var(--bg1);
  color: #1D1B20;
  padding: 5px 10px;
  border-radius: 10px;
}

.teamsNameScoreContainer>div>p:nth-child(1) {
  max-width: 50%;
}

.teamsNameScoreContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.teamsNameScoreContainer>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teamsNameScoreContainer>div>p {
  margin: 0;
}

.teamsNameScoreContainer>div>p>span {
  display: inline-block;
  background: #CAFFBF;
  width: 70px;
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
}

.teamsNameScoreContainer>div:nth-child(2)>p>span {
  display: inline-block;
  background: #FFADAD;
  width: 70px;
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
}

.matchCardBtnContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  margin-top: 10px;
}

.matchCardBtnContainer>button {
  border: 1px solid var(--netural-color-900);
  background: none;
  font-size: 14px;
  font-family: 'DM Sans', sans-serif;
  padding: 5px 10px;
  border-radius: 10px;
  background: var(--netural-color-900);
  color: #fff;
}

.matchCardBtnContainer>button[data-content="share"] {
  color: var(--netural-color-900);
  background: none;
}

/* MatchCard Compoent CSS End */

.icon-pic-container {
  display: flex;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.icon-pic {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); */
}
.profile-pic-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 10%;
}

.profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.profile-pic p {
    display: flex;
    font-size: 45px;
    color: 'var(--primary-color-600)';
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
}

.camera-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
}

.text-color {
    color: 'var(--primary-color-600)'
}

.top-space {
    margin-top: 20%;
}

.card-padding {
    margin: 0px;
    padding-top: 5%;
    padding-bottom: 0px;
}

.card-padding .card-content p {
    padding: 0px;
    margin: 0px;
}

.player-card-details {
    width: 100%;
    margin: 0px;
    padding-right: 5%;
    padding-left: 5%;
}

.flex-container {
    display: flex;
    justify-content: space-between;
}

.flex-item {
    width: 48%;
}

.error-message {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

.div-profile {
    padding: 5%;
}

.top-padding {
    padding-top: 10%;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    z-index: 1;
}

.modal-content {
    height: 100%;
    width: 100%;
    background: white;
    padding: 20px;
    border-radius: 8px;
    overflow-y: scroll;
    padding-top: 56px;
    /* padding-bottom: 150px; */
}

.card-invite-player {
    flex: 1 1;
    width: 100%;
}

/* .card-invite-player{
    display: "flex";
    margin: 0px;
    padding: 0px;
    flex-direction: "row";
    justify-content: "center";
    align-items: "flex-start";
  } */
.card-invite-player p {
    padding: 5px;
    margin: 0px;
    text-align: left;
    font-size: 14px;
    /* color: #005500; */
    font-family: 'DM Sans', sans-serif;
    color: "var(--primary-color-700)";
}

.card-invite-player .p1 {
    padding: 5px;
    margin: 0px;
    margin-bottom: 5px;
    text-align: left;
    font-size: 12px;
    font-family: 'DM Sans', sans-serif;
    color: "var(--primary-color-700)";
}

.card-invite-player img {
    width: 60px;
    height: 60px;

}

.abcd {
    z-index: 999;
}

.padding-scroll-bottom {
    padding-bottom: 150px;
}
.slider-img {
  border-radius: 10px;
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

.rec.rec-dot.rec-dot_active {
  background-color: var(--bg6);
  box-shadow: 0 0 1px 3px var(--bg6);
}

.text-header {
  margin: 10px !important;
  font-size: 20px;
  font-family: "DM Sans", sans-serif;
  color: "var(--primary-color-700)";
}

.upcomming {
  background-color: #EFF8FF;
  /* height: 70%; */
  min-height: 50vh;
  padding-left: 15px;
  padding-right: 15px;
}

.upcomming>.text-header {
  padding-top: 10px;
}

.no-matches {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  background-color: #2549720D;
}

.no-matches div {
  padding-right: 15px;
}

.no-matches p {
  width: 65%;
  text-align: center;
  padding: 0px !important;
  margin: 0px !important;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid black;
}
.intro-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro-modal-content {
    position: relative;
    width: 90%;
    height: 90%;
    /* max-width: 600px; */
    background-color: white;
    padding: 3%;
    border-radius: 10px;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.skip {
    /* position: absolute;
    top: 20px;
    right: 20px; */
    /* cursor: pointer;
    color: #6f6c6f;
    font-family: DM Sans;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: end;
    padding: 5px; */
    display: flex;
    justify-content: end;
}

.slide {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
}

.slide-img {
    margin-left: 10%;
    width: 350px;
    /* max-width: 200px; */
    height: 350px;
}

.intro-card {
    margin-top: 20px;
    width: 100%;
    background: linear-gradient(90deg, var(--netural-color-900), #8bb0da);
    color: #fff;
    border-radius: 10px !important;
    padding: 20px !important;
}

.title {
    font-family: Poppins !important;
    font-size: 22px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    text-align: left;
    margin: 5px 0 !important;
    color: #fff !important;
}

.description {
    font-family: Poppins !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    text-align: center;
    margin: 15px 0 !important;
    color: #fff !important;
}

.next-button {
    display: flex !important;
    align-self: center;
    margin: 30px auto !important;
    width: 100%;
}

.slick-next,
.slick-prev {
    display: none !important;
}
.profile-pic-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 10%;
}

.profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.profile-pic p {
    display: flex;
    font-size: 45px;
    color: 'var(--primary-color-600)';
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
}

.camera-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
}

.text-color {
    color: 'var(--primary-color-600)'
}

.top-space {
    margin-top: 20%;
}

.card-padding {
    margin: 0px;
    padding-top: 5%;
    padding-bottom: 0px;
}

.card-padding .card-content p {
    padding: 0px;
    margin: 0px;
}

.player-card-details {
    width: 100%;
    margin: 0px;
    padding-right: 5%;
    padding-left: 5%;
}

.flex-container {
    display: flex;
    justify-content: space-between;
}

.flex-item {
    width: 48%;
}

.error-message {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

.div-profile {
    padding: 5%;
}

.top-padding {
    padding-top: 10%;
}

.create-new-team-card {
    flex: 1 1;
    width: 100%;
}

/* .create-new-team-card{
    display: "flex";
    margin: 0px;
    padding: 0px;
    flex-direction: "row";
    justify-content: "center";
    align-items: "flex-start";
  } */
.create-new-team-card p {
    padding: 5px;
    margin: 0px;
    text-align: left;
    font-size: 14px;
    /* color: #005500; */
    font-family: 'DM Sans', sans-serif;
    color: "var(--primary-color-700)";
}

.create-new-team-card .p1 {
    padding: 5px;
    margin: 0px;
    margin-bottom: 5px;
    text-align: left;
    font-size: 12px;
    font-family: 'DM Sans', sans-serif;
    color: "var(--primary-color-700)";
}

.create-new-team-card img {
    width: 60px;
    height: 60px;

}

.abcd {
    z-index: 999;
}

.padding-scroll-bottom {
    padding-bottom: 150px;
}
/* src/App.css */
body {
  font-family: 'DM Sans', sans-serif;
}

input,
h1,
p,
textarea,
select {
  font-family: 'DM Sans', sans-serif;
}

/* Apply to Material-UI TextField inputs */
.MuiInputBase-input {
  font-family: 'DM Sans', sans-serif;
}

/* @media (max-width: 770px) {
  .container {
    margin: 0 10%;
  }

  .image {
    width: 100%;
  }
}


@media (max-width: 770px) {
  .container {
    margin: 0 10%;
  }

  .image {
    width: 100%;
  }
} */

/* ----------------- padding css start  */
.p20 {
  padding: 20px;
}

/* ----------------- padding css end  */

/* ----------------- flex css start  */

.flex {
  display: flex;
}

/* ----------------- flex css end  */

/* ----------------- gap css start  */

.g20 {
  grid-gap: 20px;
  gap: 20px;
}

/* ----------------- gap css end  */

/* ----------------- font size css start  */
.f1113400 {
  font-size: 11px;
  line-height: 13px;
  font-weight: 400;
  margin: 0;
}

.f1318400 {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  margin: 0;
}

.f1419400 {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  margin: 0;
}

.f1520400 {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  margin: 0;
}

.f1520600 {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  margin: 0;
}

.f1823400 {
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  margin: 0;
}


.f2025400 {
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  margin: 0;
}


.f2632500 {
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  margin: 0;
}

.f3239400 {
  font-size: 32px;
  line-height: 39px;
  font-weight: 400;
  margin: 0
}

/* ----------------- font size css end  */

.pageMainContainer {
  height: calc(100dvh - 56px);
  padding-top: 57px;
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background: #fff;
}

.pageInnerContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* --------------------- bottom sheet --------------------- */
.bottomSheetContainer {
  padding: 0 20px;
}

/* --------------------- for radio button --------------------- */
.bottomSheetBody .MuiButtonBase-root.MuiIconButton-root.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary {
  padding: 0;
}

.bottomSheetBody .MuiButtonBase-root.MuiIconButton-root.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary .MuiIconButton-label .MuiSvgIcon-root {
  /* border: 1px solid red; */
  width: 32px;
  height: 32px;
  fill: var(--placeholder-bg);
  background: var(--placeholder-bg);
  border-radius: 50%;
}

.bottomSheetBody .MuiButtonBase-root.MuiIconButton-root.MuiRadio-root.MuiRadio-colorSecondary.MuiRadio-colorSecondary.Mui-checked.MuiIconButton-colorSecondary .MuiIconButton-label .MuiSvgIcon-root {
  height: 32px;
  width: 32px;
  border: 7px solid var(--placeholder-bg);
  fill: var(--netural-color-900);
  background: var(--netural-color-900);
  transition: all 0.5s ease-in-out;
}

.bottomSheetBody .MuiButtonBase-root.MuiIconButton-root.MuiRadio-root.MuiRadio-colorSecondary.MuiRadio-colorSecondary.Mui-checked.MuiIconButton-colorSecondary .MuiIconButton-label {
  transform: scale(1);
}

/* .bottomSheetBody .MuiButtonBase-root.MuiIconButton-root.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary .MuiIconButton-label .MuiSvgIcon-root {
  
} */

/* .bottomSheetBody .MuiButtonBase-root.MuiIconButton-root.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary .MuiIconButton-label
.PrivateRadioButtonIcon-root-15.PrivateRadioButtonIcon-checked-17 .PrivateRadioButtonIcon-layer-16 {
  transform: scale(0.7);
} */

/* --------------------- for bottom sheet create using drawer --------------------- */
.MuiDrawer-root .MuiDrawer-paperAnchorBottom {
  border-radius: 30px 30px 0 0;
}

/* --------------------- for tabs --------------------- */
.MuiTabs-root .MuiTabs-scrollable .MuiTabs-flexContainer .MuiTab-root .MuiTab-wrapper {
  text-transform: capitalize;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.MuiTabs-root .MuiTabs-flexContainer .MuiTab-root .MuiTab-wrapper {
  text-transform: capitalize;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.profile-pic-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 10%;
}

.profile-pic {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: contain;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}
.profile-pic p{
    display: flex;
    font-size: 45px;
    color:'var(--primary-color-600)';
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
}
.camera-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
}
.text-color {
    color:'var(--primary-color-600)'
}
.top-space {
    margin-top: 20%;
}

.card-padding{
    margin: 0px;
    padding-top: 5%;
    padding-bottom: 0px;
}
.card-padding .card-content p{
    padding: 0px;
    margin: 0px;
}   
.player-card-details{
    width: 100%;
    margin: 0px;
    /* padding-right: 5%;
    padding-left: 5%; */
}

.flex-container {
    display: flex;
    justify-content: space-between;
}

.flex-item {
    width: 48%;
}

.error-message {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}
.div-profile{
    padding: 5%;
}
.MyMatches_matchCardContainer__3vXUH {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    padding-bottom: 20px;
}
.ScoreSheetModal_loaderContainer__1zP27{
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    z-index: 2;
}

.ScoreSheetModal_scoresheetMainContainer__SEg5W {
    height: calc(100vh - 56px);
    margin-top: 56px;
    grid-gap: 0px;
    gap: 0px;
}

.ScoreSheetModal_scrshtcntr1__114pq {
    display: grid;
    grid-template-columns: 30% 70%;
    text-align: center;
    background: #1940550D;
    padding: 5px 0px 0px 0px;
}


.ScoreSheetModal_scrshtcntr1__114pq>.ScoreSheetModal_overContainer__xr6ew>p {
    margin: 0;
}

.ScoreSheetModal_scrshtcntr1__114pq>.ScoreSheetModal_overContainer__xr6ew>p:nth-child(1) {
    font-size: 24px;
    line-height: 28px;
}

.ScoreSheetModal_scrshtcntr1__114pq>.ScoreSheetModal_overContainer__xr6ew>p:nth-child(2),
.ScoreSheetModal_scrshtcntr1__114pq>.ScoreSheetModal_overContainer__xr6ew>p:last-child {
    font-size: 28px;
}

.ScoreSheetModal_scrshtcntr1__114pq>.ScoreSheetModal_overContainer__xr6ew>p:nth-child(3) {
    font-size: 16px;
    font-weight: 500;
    color: var(--color3);
}

.ScoreSheetModal_scrshtcntr1__114pq>.ScoreSheetModal_overContainer__xr6ew>hr:nth-child(4) {
    width: 60%;
}

.ScoreSheetModal_scrshtcntr1__114pq>.ScoreSheetModal_overContainer__xr6ew>p:nth-child(5) {
    font-size: 16px;
    font-weight: 600;
    color: var(--color3);
}


/* batsmanContainer */
.ScoreSheetModal_batsmanContainer__2iSe5 {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
}

.ScoreSheetModal_batsManCard__4-4Gj {
    background: var(--bg5);
    display: flex;
    flex-direction: column;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
}

.ScoreSheetModal_batsManCard__4-4Gj.ScoreSheetModal_play__7ndMA {
    background: var(--netural-color-900);
}

.ScoreSheetModal_batsManCard__4-4Gj>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ScoreSheetModal_batsManCard__4-4Gj>div>p {
    margin: 0;
    color: #fff;
}

.ScoreSheetModal_batsManCard__4-4Gj>div:nth-child(1)>p {
    font-size: 18px;
    font-weight: 400;
}

.ScoreSheetModal_batsManCard__4-4Gj>div:nth-child(1)>p>span {
    font-size: 15px;
}


.ScoreSheetModal_batsmanBtnContainer__2dcFJ {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ScoreSheetModal_batsmanBtnContainer__2dcFJ button {
    background: var(--card-1-bg);
    border: none;
    color: var(--color3);
    font-size: 15px;
    padding: 8px 5px;
    display: flex;
    align-items: center;
    border-radius: 15px;
    justify-content: center;
}

/* ball container */
.ScoreSheetModal_scrshtcntr2__28pmy {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    padding: 10px 0 18px 0;
}

.ScoreSheetModal_ball__1Pw6Z {
    height: 30px;
    width: 30px;
    background: #FFADAD;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: relative;
}

.ScoreSheetModal_ball__1Pw6Z.ScoreSheetModal_run__3WFMq {
    background: var(--bg6);
}

.ScoreSheetModal_ball__1Pw6Z.ScoreSheetModal_run0__yD74Q {
    background: #FFADAD;
}

.ScoreSheetModal_ball__1Pw6Z.ScoreSheetModal_run1__2O1H- {
    background: #2E8DFF;
}

.ScoreSheetModal_ball__1Pw6Z.ScoreSheetModal_run2__2wFdP {
    background: #9BF6FF;
}

.ScoreSheetModal_ball__1Pw6Z.ScoreSheetModal_run3__yRhdz {
    background: #BDB2FF;
}

.ScoreSheetModal_ball__1Pw6Z.ScoreSheetModal_run4__3330d {
    background: #CAFFBF;
}

.ScoreSheetModal_ball__1Pw6Z.ScoreSheetModal_run6__1qvcQ {
    background: #9747FF;
}

.ScoreSheetModal_ball__1Pw6Z.ScoreSheetModal_wicket__1Of7L {
    background: #C80000;
}

.ScoreSheetModal_ball__1Pw6Z.ScoreSheetModal_noball__3HOtV {
    background: var(--bg6);
    font-size: 11px;
    font-weight: 400;
    word-break: break-all;
    text-align: center;
}

.ScoreSheetModal_ball__1Pw6Z.ScoreSheetModal_wideball__3t4l8 {
    background: var(--bg6);
    font-size: 11px;
    font-weight: 400;
    word-break: break-all;
    text-align: center;
}

.ScoreSheetModal_ball__1Pw6Z.ScoreSheetModal_freeHit__20zKL::after {
    content: 'Free Hit';
    position: absolute;
    top: 100%;
    height: 100%;
    width: auto;
    font-size: 10px;
    color: #C80000;
}

/* bowler conatiner */
.ScoreSheetModal_scrshtcntr3__MOPDj {
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    padding: 10px;
}

.ScoreSheetModal_bowlerCard__7Rbyo {
    background: #63A2C8;
    display: flex;
    align-items: center;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    justify-content: space-between;
}

.ScoreSheetModal_bowlerCard__7Rbyo>div>p {
    margin: 0;
}

.ScoreSheetModal_bowlerCard__7Rbyo>div {
    display: flex;
    flex-direction: column;
    color: #fff;
}

.ScoreSheetModal_bowlerCard__7Rbyo>div:nth-child(1)>p {
    font-size: 15px;
}

.ScoreSheetModal_bowlerCard__7Rbyo>div:nth-child(2) {
    align-items: center;
}

.ScoreSheetModal_bowlerCard__7Rbyo>div:nth-child(2)>p:nth-child(1) {
    font-size: 15px;
}

.ScoreSheetModal_scrshtcntr3__MOPDj>button {
    margin: 0 0 0 10px;
    background: var(--card-1-bg);
    border: none;
    color: var(--color3);
    font-size: 15px;
    padding: 8px 3px;
    display: flex;
    align-items: center;
    border-radius: 15px;
    justify-content: center;
}

/* need run container */

.ScoreSheetModal_scrshtcntr4__3qhoq {
    background: var(--bg5);
    padding: 5px 10px;
}

.ScoreSheetModal_scrshtcntr4__3qhoq p {
    margin: 0;
}

.ScoreSheetModal_firstBattingTeamsConatiner__wRs9Z {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ScoreSheetModal_firstBattingTeamsConatiner__wRs9Z>div:nth-child(1) {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.ScoreSheetModal_firstBattingTeamsConatiner__wRs9Z>div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 5px;
    gap: 5px;
}

.ScoreSheetModal_firstBattingTeamsConatiner__wRs9Z>div:nth-child(1)>p:nth-child(2) {
    font-size: 24px;
}

.ScoreSheetModal_firstBattingTeamsConatiner__wRs9Z>div:nth-child(2)>p {
    background: #fff;
    font-size: 15px;
    padding: 5px;
    line-height: 20px;
    border-radius: 3px;
    color: var(--color3);
}

.ScoreSheetModal_firstBattingTeamsConatiner__wRs9Z>div:nth-child(2)>p>span {
    font-weight: 700;
    color: #000;
}

.ScoreSheetModal_scrshtcntr4__3qhoq>p:last-child {
    margin-left: 30px;
    font-size: 18px;
}

/* Scoring Div */

.ScoreSheetModal_scrshtcntr5__VGKqL {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 -4px 6px -2px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    padding: 10px 5px;
}

.ScoreSheetModal_cntr5BtnsDiv__17DGr {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    flex-wrap: wrap;
}

.ScoreSheetModal_cntr5DigitDiv__2hsyM {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    padding: 10px 0 18px 0;
    flex-wrap: wrap;
}

.ScoreSheetModal_cntr5Digit__31PjN {
    background-color: var(--bg6);
    /* padding: 20px 50px; */
    font-size: 40px;
    font-weight: 900;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
}

.ScoreSheetModal_cntr5DigitDisable__3ZjYK {
    background-color: var(--bg7);
}

.ScoreSheetModal_cntr5LowerRow__mgeIr {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    gap: 10px;
}

.ScoreSheetModal_cntr5LowerDiv__2L9tT {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ScoreSheetModal_cntr5Lower1Div__2iAUi {
    background-color: #EFF8FF;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    font-family: DM Sans;
    font-size: 32px;
    font-weight: 400;
    width: 52px;
    height: 52px;
    line-height: 34px;
}

.ScoreSheetModal_darkbold__MQK7u {
    font-weight: 900;
}
.sheet-header-text {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--primary-color-700);
}

.sheet-description-text {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--primary-color-700);
}
.BallTypeRunsBottomSheet {
    padding-top: 10px;
}
.SelectedMatchCompo_mainCT__2LhPV {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
}

.SelectedMatchCompo_container2__34hHY {
    background: #2549720D;
    border-radius: 20px;
    padding: 10px 15px;
    box-shadow: 0px 4px 10px 0px #25497240;
}


.SelectedMatchCompo_container2CP1__2HQhR,
.SelectedMatchCompo_container2CD2P1__1Nrse {
    margin: 0;
    background: var(--bg1);
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
}

.SelectedMatchCompo_container2CD2__vz4nx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.SelectedMatchCompo_container2CD2__vz4nx:last-child {
    margin-bottom: 0px;
}

.SelectedMatchCompo_container2CP1__2HQhR,
.SelectedMatchCompo_container2CD2P1__1Nrse {
    font-size: 16px;
    padding: 3px 10px;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-bottom: 10px;
    color: var(--netural-color-900);
}

.SelectedMatchCompo_container2CD2P1__1Nrse {
    box-shadow: none;
    font-size: 14px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    color: #1D1B20;
}

.SelectedMatchCompo_container2CD2CD2__3DA1r {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.SelectedMatchCompo_last5WBtns__3LSPm {
    height: 30px;
    width: 30px;
    background: var(--bg2);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.SelectedMatchCompo_last5WBtnsLoss__pesz2 {
    background: #FF6961;
}

.SelectedMatchCompo_AdContainer__2MRxT {
    height: 100px;
    background: linear-gradient(0deg, rgba(238, 240, 255, 0.29), rgba(238, 240, 255, 0.29));
    border-radius: 20px;
}


.SelectedMatchCompo_playerMainContainer__3t816 {
    background: #1940550D;
    padding: 1.25rem;
    border-radius: 20px;
    margin-bottom: 70px;
}

.SelectedMatchCompo_teamNameContainer__-yIW_ {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SelectedMatchCompo_teamNameBtn__106V8 {
    border: none;
    color: #494BAD;
    background: none;
    font-size: 1.1rem;
    border-bottom: 2px solid #494BAD;
    border-radius: 15px;
    padding: 8px 10px;
    flex: 1 1;
    transition: all ease-in-out 0.5s;
}

.SelectedMatchCompo_teamNameBtn__106V8.SelectedMatchCompo_active__1NOUl {
    background: #494BAD;
    color: #F0EDF1;
}

.SelectedMatchCompo_playerListContainer__1aPrK{
    margin-top: 20px;
}
.MatchToss_AdContainer__ZU4bF {
    height: 100px;
    background: linear-gradient(0deg, rgba(238, 240, 255, 0.29), rgba(238, 240, 255, 0.29));
    border-radius: 10px;
    display: grid;
    place-items: center;
}
.TeamInfoBigCard {
    background: var(--bg8);
    overflow-x: hidden;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.TeamInfoBigCard .teamLogo {
    width: 100%;
    height: 133px;
    object-fit: cover;
    object-position: center;
}

.TeamInfoBigCard .teamInfoContainer {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}
.file-upload-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px dashed var(--primary-color-600);
    border-radius: 10px;
    text-align: center;
}

.file-upload-container input {
    display: none;
}

.file-upload-container img {
    width: 100%;
    border-radius: 15px;
}

.MuiPaper-root.MuiCard-root.team-info-card {
    box-shadow: var(--shadow3);
    border-radius: 10px;
}

.team-info-card .avatar {
    height: 72px;
    width: 72px;
    border-radius: 20px;
}

.team-info-card .isAdmin{
    min-height: 22px;
    background: var(--bg9);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    border-radius: 7px;
    color: var(--bg6);
}


.team-info-card .text-container {
    background: var(--bg1);
    min-height: 22px;
    border-radius: 2px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
}

.team-info-card .vertical-line {
    min-height: 22px;
    width: 1px;
    background: #000
}
.palyer-card-with-score {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    padding: 0.5rem 0;
}

.palyer-card-with-score::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 0.063rem;
    width: 80%;
    background: var(--netural-color-900);
}

.palyer-card-with-score .avatar {
    height: 48px;
    width: 48px;
}

.palyer-card-with-score .score-container {
    margin-left: auto;
    min-width: 4.875rem;
    padding: 0.2rem 0.7rem;
    border-radius: 0.625rem;
    text-align: center;
}
