.file-upload-container {
    height: fit-content;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px dashed var(--primary-color-600);
    border-radius: 10px;
    text-align: center;
}

.file-upload-container input {
    display: none;
}

.file-upload-container img {
    width: 100%;
    border-radius: 15px;
}
