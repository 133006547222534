@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

/* src/App.css */
body {
  font-family: 'DM Sans', sans-serif;
}

input,
h1,
p,
textarea,
select {
  font-family: 'DM Sans', sans-serif;
}

/* Apply to Material-UI TextField inputs */
.MuiInputBase-input {
  font-family: 'DM Sans', sans-serif;
}

/* @media (max-width: 770px) {
  .container {
    margin: 0 10%;
  }

  .image {
    width: 100%;
  }
}


@media (max-width: 770px) {
  .container {
    margin: 0 10%;
  }

  .image {
    width: 100%;
  }
} */

/* ----------------- padding css start  */
.p20 {
  padding: 20px;
}

/* ----------------- padding css end  */

/* ----------------- flex css start  */

.flex {
  display: flex;
}

/* ----------------- flex css end  */

/* ----------------- gap css start  */

.g20 {
  gap: 20px;
}

/* ----------------- gap css end  */

/* ----------------- font size css start  */
.f1113400 {
  font-size: 11px;
  line-height: 13px;
  font-weight: 400;
  margin: 0;
}

.f1318400 {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  margin: 0;
}

.f1419400 {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  margin: 0;
}

.f1520400 {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  margin: 0;
}

.f1520600 {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  margin: 0;
}

.f1823400 {
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  margin: 0;
}


.f2025400 {
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  margin: 0;
}


.f2632500 {
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  margin: 0;
}

.f3239400 {
  font-size: 32px;
  line-height: 39px;
  font-weight: 400;
  margin: 0
}

/* ----------------- font size css end  */

.pageMainContainer {
  height: calc(100dvh - 56px);
  padding-top: 57px;
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background: #fff;
}

.pageInnerContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* --------------------- bottom sheet --------------------- */
.bottomSheetContainer {
  padding: 0 20px;
}

/* --------------------- for radio button --------------------- */
.bottomSheetBody .MuiButtonBase-root.MuiIconButton-root.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary {
  padding: 0;
}

.bottomSheetBody .MuiButtonBase-root.MuiIconButton-root.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary .MuiIconButton-label .MuiSvgIcon-root {
  /* border: 1px solid red; */
  width: 32px;
  height: 32px;
  fill: var(--placeholder-bg);
  background: var(--placeholder-bg);
  border-radius: 50%;
}

.bottomSheetBody .MuiButtonBase-root.MuiIconButton-root.MuiRadio-root.MuiRadio-colorSecondary.MuiRadio-colorSecondary.Mui-checked.MuiIconButton-colorSecondary .MuiIconButton-label .MuiSvgIcon-root {
  height: 32px;
  width: 32px;
  border: 7px solid var(--placeholder-bg);
  fill: var(--netural-color-900);
  background: var(--netural-color-900);
  transition: all 0.5s ease-in-out;
}

.bottomSheetBody .MuiButtonBase-root.MuiIconButton-root.MuiRadio-root.MuiRadio-colorSecondary.MuiRadio-colorSecondary.Mui-checked.MuiIconButton-colorSecondary .MuiIconButton-label {
  transform: scale(1);
}

/* .bottomSheetBody .MuiButtonBase-root.MuiIconButton-root.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary .MuiIconButton-label .MuiSvgIcon-root {
  
} */

/* .bottomSheetBody .MuiButtonBase-root.MuiIconButton-root.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary .MuiIconButton-label
.PrivateRadioButtonIcon-root-15.PrivateRadioButtonIcon-checked-17 .PrivateRadioButtonIcon-layer-16 {
  transform: scale(0.7);
} */

/* --------------------- for bottom sheet create using drawer --------------------- */
.MuiDrawer-root .MuiDrawer-paperAnchorBottom {
  border-radius: 30px 30px 0 0;
}

/* --------------------- for tabs --------------------- */
.MuiTabs-root .MuiTabs-scrollable .MuiTabs-flexContainer .MuiTab-root .MuiTab-wrapper {
  text-transform: capitalize;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.MuiTabs-root .MuiTabs-flexContainer .MuiTab-root .MuiTab-wrapper {
  text-transform: capitalize;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}