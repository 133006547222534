.palyer-card-with-score {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;
    padding: 0.5rem 0;
}

.palyer-card-with-score::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 0.063rem;
    width: 80%;
    background: var(--netural-color-900);
}

.palyer-card-with-score .avatar {
    height: 48px;
    width: 48px;
}

.palyer-card-with-score .score-container {
    margin-left: auto;
    min-width: 4.875rem;
    padding: 0.2rem 0.7rem;
    border-radius: 0.625rem;
    text-align: center;
}